.ui.category.fluid.search .ui.icon.input {
  width: 100%;
}

.ui.category.search input {
  font-size: 16px;
}

.results.transition.visible {
  overflow-y: scroll;
  max-height: 60vh;
}
