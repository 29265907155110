html {
  height: 100%;
}
body,
#root {
  height: 100%;
  min-height: 100%;
}
body {
  font-family: 'Roboto', sans-serif;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.ui.toggle.checkbox.inverted label,
.ui.toggle.checkbox.inverted.checked label,
.ui.toggle.checkbox.inverted input:focus ~ label {
  color: white !important;
}
.ui.toggle.checkbox.inverted:not(.checked) label::before {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.ui.pink.button:not(.inverted) {
  background-color: #ef4b6f !important;
}
.ui.pink.button:not(.inverted):hover {
  background-color: #ff567a !important;
}
.ui.pink.button:not(.inverted):active,
.ui.pink.button:not(.inverted):focus {
  background-color: #ca4360 !important;
}

.ui.pink.button.inverted {
  color: #ef4b6f !important;
  box-shadow: 0 0 0 2px #ef4b6f inset !important;
}
.ui.pink.button.inverted:hover {
  background-color: #ff567a !important;
  color: white !important;
}
.ui.pink.button.inverted:active,
.ui.pink.button.inverted:focus {
  background-color: #ca4360 !important;
  color: white !important;
}

.ocr.link {
  color: #4183c4;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #1e70bf;
    text-decoration: none;
  }
}

.footer .ui.container {
  padding-top: 0rem !important;
  padding-bottom: 1rem !important;

  .column {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .footer .column {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .ui.stackable.divided:not(.vertically).grid > .column:not(.row),
  .ui.stackable.divided:not(.vertically).grid
    > .row
    > .column.no.vertical.padding {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
